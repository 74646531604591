"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class JSONStorageItem {
    constructor(key) {
        this.key = key;
    }
    get() {
        const maybeStr = localStorage.getItem(this.key);
        return maybeStr !== null ? JSON.parse(maybeStr) : null;
    }
    set(value) {
        this.setRaw(JSON.stringify(value));
    }
    setRaw(str) {
        localStorage.setItem(this.key, str);
    }
    remove() {
        return localStorage.removeItem(this.key);
    }
}
exports.default = JSONStorageItem;
